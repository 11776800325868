import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from '../../redux/store';
import { current } from '@reduxjs/toolkit'


export interface NavigationState {
    currentPage: number;
    currentPageURL: string

    scrollY: number;
    scrollYMobile: number;

    navigationHeight: number;
    homeHeight: number;
    aboutHeight: number;
    significantHeight: number;
    contactHeight: number;

    navigationHeightMobile: number;
    homeHeightMobile: number;
    aboutHeightMobile: number;
    significantHeightMobile: number;
    contactHeightMobile: number;
 
}

const initialState: NavigationState = {
    currentPage: 0,
    currentPageURL: "#home",

    scrollY: 0,
    scrollYMobile: 0,

    navigationHeight: 0,
    homeHeight: 0,
    aboutHeight: 0,
    significantHeight: 0,
    contactHeight: 0,

    navigationHeightMobile: 0,
    homeHeightMobile: 0,
    aboutHeightMobile: 0,
    significantHeightMobile: 0,
    contactHeightMobile: 0,
 
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.

export const navigationSlice = createSlice({
    name: 'navigation',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        setCurrentPage: (state, action: PayloadAction<number>) => {
            state.currentPage = action.payload; 
        },
        setCurrentPageURL: (state, action: PayloadAction<string>) => {
            state.currentPageURL = action.payload;
        },
        // Use the PayloadAction type to declare the contents of `action.payload`
        setScrollY: (state, action: PayloadAction<number>) => {
            state.scrollY = action.payload;
            /* console.log(current(state)) */
        },
        setNavigationHeight: (state, action: PayloadAction<number>) => {
            state.navigationHeight = action.payload; 
        },
        setHomeHeight: (state, action: PayloadAction<number>) => {
            state.homeHeight = action.payload; 
        },
        setAboutHeight: (state, action: PayloadAction<number>) => {
            state.aboutHeight = action.payload; 
        },
        setSignificantHeight: (state, action: PayloadAction<number>) => {
            state.significantHeight = action.payload; 
        },
        setContactHeight: (state, action: PayloadAction<number>) => {
            state.contactHeight = action.payload; 
        },
        setScrollYMobile: (state, action: PayloadAction<number>) => {
            state.scrollYMobile = action.payload;
            /* console.log(current(state)) */
        },
        setNavigationHeightMobile: (state, action: PayloadAction<number>) => {
            state.navigationHeightMobile = action.payload; 
        },
        setHomeHeightMobile: (state, action: PayloadAction<number>) => {
            state.homeHeightMobile = action.payload; 
        },
        setAboutHeightMobile: (state, action: PayloadAction<number>) => {
            state.aboutHeightMobile = action.payload; 
        },
        setSignificantHeightMobile: (state, action: PayloadAction<number>) => {
            state.significantHeightMobile = action.payload; 
        },
        setContactHeightMobile: (state, action: PayloadAction<number>) => {
            state.contactHeightMobile = action.payload; 
        },
        
    },
    // The `extraReducers` field lets the slice handle actions defined elsewhere,
    // including actions generated by createAsyncThunk or in other slices.
    /* extraReducers: (builder) => {
        builder
            .addCase(incrementAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(incrementAsync.fulfilled, (state, action) => {
                state.status = 'idle';
                state.value += action.payload;
            });
    }, */
});

export const { setCurrentPage, setCurrentPageURL, setScrollY, setNavigationHeight, setHomeHeight, setAboutHeight, setSignificantHeight, setContactHeight, setScrollYMobile, setNavigationHeightMobile, setHomeHeightMobile, setAboutHeightMobile, setSignificantHeightMobile, setContactHeightMobile  } = navigationSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectCurrentPage = (state: RootState) => state.navigation.currentPage;

export const selectCurrentPageURL = (state: RootState) => state.navigation.currentPageURL;

export const selectScrollY = (state: RootState) => state.navigation.scrollY;

export const selectNavigationHeight = (state: RootState) => state.navigation.navigationHeight;

export const selectHomeHeight = (state: RootState) => state.navigation.homeHeight;

export const selectAboutHeight = (state: RootState) => state.navigation.aboutHeight;

export const selectAboutBottom = (state: RootState) => state.navigation.navigationHeight + state.navigation.homeHeight + state.navigation.aboutHeight ;

export const selectSignificantHeight = (state: RootState) => state.navigation.significantHeight;

export const selectContactHeight = (state: RootState) => state.navigation.contactHeight;

export const selectScrollYMobile = (state: RootState) => state.navigation.scrollYMobile;

export const selectNavigationHeightMobile = (state: RootState) => state.navigation.navigationHeightMobile;

export const selectHomeHeightMobile = (state: RootState) => state.navigation.homeHeightMobile;

export const selectAboutHeightMobile = (state: RootState) => state.navigation.aboutHeightMobile;

export const selectAboutBottomMobile = (state: RootState) => state.navigation.navigationHeightMobile + state.navigation.homeHeightMobile + state.navigation.aboutHeightMobile ;

export const selectSignificantHeightMobile = (state: RootState) => state.navigation.significantHeightMobile;

export const selectContactHeightMobile = (state: RootState) => state.navigation.contactHeightMobile;
// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.
/* export const incrementIfOdd = (amount: number): AppThunk => (
    dispatch,
    getState
) => {
    const currentValue = selectCount(getState());
    if (currentValue % 2 === 1) {
        dispatch(incrementByAmount(amount));
    }
}; */

export default navigationSlice.reducer;
